import React from 'react';
import { Link } from 'react-router-dom';

const MilestoneNotificationItem = ({ data }) => {
    return (
        <div className="notification-item">
            <div>
                <img src={data.userPhotoURL} alt={data.userName} />
                <div className="notification-content">
                    <span>
                        <strong>{data.userName}</strong> reached a milestone: {data.milestone}
                    </span>
                    <span className="timestamp">{data.formattedTimestamp}</span>
                </div>
            </div>
            <div className="notification-item-footer">
                <Link to={`/profile/${data.userId}`} className="view-profile-button">View Profile</Link>
                <button className="congrats-button">Congratulate</button>
            </div>
        </div>
    );
};

export default MilestoneNotificationItem;
