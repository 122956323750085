import React, { useState, useEffect, useRef } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { 
    subscribeToUserConversationsWithInfo, 
    subscribeToMessages, 
    sendMessage, 
    updateTypingStatus,
    getOrCreateConversationWithUserInfo,
    markMessageAsRead,
    addReaction,
    removeReaction,
    updateLastSeen,
    getLastSeen
} from '../services/messageService';
import styled from 'styled-components';
import { formatDistanceToNow } from 'date-fns';
import { FiEdit, FiSearch, FiCheck, FiPaperclip, FiImage, FiSmile } from 'react-icons/fi';
import { collection, query, where, getDocs } from 'firebase/firestore';
import { firestore } from '../services/firebase';
import { fetchUserFollowing, fetchUserFollowers, getUserData } from '../services/userProfileService';
import { auth } from '../services/firebase';

const MessagesContainer = styled.div`
    display: flex;
    height: calc(100vh - 60px);
    background: var(--background);
`;

const ConversationsList = styled.div`
    width: 300px;
    border-right: 1px solid var(--border);
    overflow-y: auto;
`;

const ConversationItem = styled.div`
    padding: 15px;
    border-bottom: 1px solid var(--border);
    cursor: pointer;
    &:hover {
        background: var(--hover);
    }
    ${props => props.active && `
        background: var(--primary-light);
    `}
`;

const ChatWindow = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
`;

const MessagesList = styled.div`
    flex: 1;
    overflow-y: auto;
    padding: 20px;
`;

const ReadReceipt = styled.span`
    display: inline-flex;
    align-items: center;
    margin-left: 4px;
    color: ${props => props.isRead ? 'var(--primary)' : 'var(--text-secondary)'};
    
    svg {
        width: 14px;
        height: 14px;
        margin-left: -4px; // Overlap the checks
    }
`;

const ReactionButton = styled.button`
    background: none;
    border: none;
    padding: 4px;
    cursor: pointer;
    color: var(--text-secondary);
    opacity: 0;
    transition: opacity 0.2s;

    &:hover {
        color: var(--text);
    }
`;

const MessageBubble = styled.div`
    max-width: 70%;
    padding: 10px 15px;
    margin: 5px 0;
    border-radius: 15px;
    position: relative;
    ${props => props.isMine ? `
        background: var(--primary);
        color: white;
        align-self: flex-end;
        margin-left: auto;
    ` : `
        background: var(--secondary-background);
        align-self: flex-start;
    `}

    &:hover {
        ${ReactionButton} {
            opacity: 1;
        }
    }

    .message-footer {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        margin-top: 4px;
        font-size: 0.8em;
        color: ${props => props.isMine ? 'rgba(255, 255, 255, 0.7)' : 'var(--text-secondary)'};
        position: relative;
    }
`;

const MessageInput = styled.div`
    padding: 20px;
    border-top: 1px solid var(--border);
    display: flex;
    gap: 10px;

    input {
        flex: 1;
        padding: 10px;
        border: 1px solid var(--border);
        border-radius: 20px;
        background: var(--background);
        color: var(--text);
    }

    button {
        padding: 10px 20px;
        border-radius: 20px;
        background: var(--primary);
        color: white;
        border: none;
        cursor: pointer;

        &:hover {
            background: var(--primary-dark);
        }
    }
`;

const ConversationHeader = styled.div`
    padding: 15px;
    border-bottom: 1px solid var(--border);
    display: flex;
    align-items: center;
    gap: 10px;
`;

const UserAvatar = styled.img`
    width: 40px;
    height: 40px;
    border-radius: 50%;
    object-fit: cover;
`;

const UserInfo = styled.div`
    display: flex;
    flex-direction: column;
    gap: 2px;
`;

const UserName = styled.div`
    font-weight: 600;
`;

const LastActive = styled.div`
    font-size: 0.8em;
    color: var(--text-secondary);
`;

const ConversationItemContent = styled.div`
    display: flex;
    gap: 10px;
    align-items: center;

    .conversation-details {
        flex: 1;
        min-width: 0;

        .last-message {
            color: var(--text-secondary);
            font-size: 0.9em;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
`;

const MessageTime = styled.span`
    font-size: 0.8em;
    color: var(--text-secondary);
    margin-left: 8px;
`;

const TypingIndicator = styled.div`
    padding: 10px;
    color: var(--text-secondary);
    font-style: italic;
`;

const ConversationListHeader = styled.div`
    padding: 15px;
    border-bottom: 1px solid var(--border);
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const NewMessageButton = styled.button`
    background: var(--primary);
    color: white;
    border: none;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: background 0.2s;

    &:hover {
        background: var(--primary-dark);
    }
`;

const SearchContainer = styled.div`
    padding: 10px 15px;
    border-bottom: 1px solid var(--border);
    position: relative;

    input {
        width: 100%;
        padding: 8px 15px 8px 35px;
        border: 1px solid var(--border);
        border-radius: 20px;
        background: var(--background);
        color: var(--text);
    }

    svg {
        position: absolute;
        left: 25px;
        top: 50%;
        transform: translateY(-50%);
        color: var(--text-secondary);
    }
`;

const EmptyState = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    color: var(--text-secondary);
    text-align: center;
    padding: 20px;

    h3 {
        margin-bottom: 10px;
    }

    p {
        margin-bottom: 20px;
    }

    button {
        padding: 10px 20px;
        background: var(--primary);
        color: white;
        border: none;
        border-radius: 20px;
        cursor: pointer;
        
        &:hover {
            background: var(--primary-dark);
        }
    }
`;

const Modal = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
`;

const ModalContent = styled.div`
    background: var(--background);
    border-radius: 8px;
    width: 90%;
    max-width: 500px;
    max-height: 80vh;
    display: flex;
    flex-direction: column;
`;

const ModalHeader = styled.div`
    padding: 15px;
    border-bottom: 1px solid var(--border);
    display: flex;
    justify-content: space-between;
    align-items: center;

    button {
        background: none;
        border: none;
        font-size: 20px;
        cursor: pointer;
        color: var(--text);
    }
`;

const UserList = styled.div`
    overflow-y: auto;
    max-height: 400px;
`;

const UserItem = styled.div`
    padding: 10px 15px;
    display: flex;
    align-items: center;
    gap: 10px;
    cursor: pointer;
    transition: background 0.2s;

    &:hover {
        background: var(--hover);
    }
`;

const ReactionsList = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 4px;
    margin-top: 4px;
`;

const Reaction = styled.span`
    background: var(--secondary-background);
    padding: 2px 6px;
    border-radius: 12px;
    font-size: 0.8em;
    cursor: pointer;
    user-select: none;
    
    &:hover {
        background: var(--hover);
    }

    &.active {
        background: var(--primary-light);
        color: var(--primary);
    }
`;

const MessageActions = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
    margin-top: 4px;
`;

const FileInput = styled.input`
    display: none;
`;

const EmojiPicker = styled.div`
    position: absolute;
    bottom: 100%;
    right: 0;
    background: var(--background);
    border: 1px solid var(--border);
    border-radius: 8px;
    padding: 8px;
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    gap: 4px;
    margin-bottom: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
`;

const REACTIONS = ['👍', '❤️', '😂', '😮', '😢', '🙏'];

const Messages = () => {
    const [user, loading] = useAuthState(auth);
    const [conversations, setConversations] = useState([]);
    const [selectedConversation, setSelectedConversation] = useState(null);
    const [messages, setMessages] = useState([]);
    const [newMessage, setNewMessage] = useState('');
    const [typingStatus, setTypingStatus] = useState({});
    const [searchQuery, setSearchQuery] = useState('');
    const messagesEndRef = useRef(null);
    const [showNewConversation, setShowNewConversation] = useState(false);
    const [followers, setFollowers] = useState([]);
    const [following, setFollowing] = useState([]);
    const [showEmojiPicker, setShowEmojiPicker] = useState(null);
    const [lastSeen, setLastSeen] = useState({});
    const fileInputRef = useRef(null);

    useEffect(() => {
        if (!user) return;

        // Subscribe to user's conversations with user info
        const unsubscribeConversations = subscribeToUserConversationsWithInfo(
            user.uid,
            (updatedConversations) => {
                setConversations(updatedConversations);
            }
        );

        return () => {
            unsubscribeConversations();
        };
    }, [user]);

    useEffect(() => {
        if (!selectedConversation) return;

        // Subscribe to messages in the selected conversation
        const unsubscribeMessages = subscribeToMessages(
            selectedConversation.id,
            (updatedMessages) => {
                setMessages(updatedMessages);
            }
        );

        return () => {
            unsubscribeMessages();
        };
    }, [selectedConversation]);

    // Fetch following and followers when component mounts
    useEffect(() => {
        const loadConnections = async () => {
            if (loading) {
                console.log('Auth is still loading...'); // Debug log
                return;
            }

            if (!user) {
                console.log('No current user after auth loaded'); // Debug log
                return;
            }
            
            console.log('Current user:', user); // Debug log
            
            try {
                const userData = await getUserData(user.uid);
                console.log('User data:', userData); // Debug log
                if (userData) {
                    console.log('Following IDs:', userData.following); // Debug log
                    const followingUsers = await fetchUserFollowing(userData.following || []);
                    console.log('Following users:', followingUsers); // Debug log
                    setFollowers(userData.followers || []);
                    setFollowing(followingUsers);
                } else {
                    console.log('No user data found'); // Debug log
                }
            } catch (error) {
                console.error('Error loading following:', error);
            }
        };

        loadConnections();
    }, [user, loading]);

    useEffect(() => {
        if (!selectedConversation || !messages.length) return;

        // Mark unread messages as read
        const unreadMessages = messages.filter(
            message => !message.read && message.senderId !== user.uid
        );

        unreadMessages.forEach(message => {
            markMessageAsRead(selectedConversation.id, message.id);
        });
    }, [selectedConversation, messages, user]);

    // Update last seen when conversation changes
    useEffect(() => {
        if (!selectedConversation || !user) return;

        const updateLastSeenStatus = async () => {
            await updateLastSeen(selectedConversation.id, user.uid);
            const otherUserLastSeen = await getLastSeen(
                selectedConversation.id, 
                selectedConversation.otherUser.uid
            );
            setLastSeen(prev => ({
                ...prev,
                [selectedConversation.otherUser.uid]: otherUserLastSeen
            }));
        };

        updateLastSeenStatus();
        
        // Update last seen every minute when conversation is open
        const interval = setInterval(updateLastSeenStatus, 60000);
        
        return () => clearInterval(interval);
    }, [selectedConversation, user]);

    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
    };

    useEffect(() => {
        scrollToBottom();
    }, [messages]);

    const filteredConversations = conversations.filter(conversation => 
        conversation.otherUser?.displayName?.toLowerCase().includes(searchQuery.toLowerCase())
    );

    const handleSelectUser = async (user) => {
        try {
            const conversation = await getOrCreateConversationWithUserInfo(user.uid, user.uid);
            setSelectedConversation(conversation);
            setShowNewConversation(false);
        } catch (error) {
            console.error('Error creating conversation:', error);
        }
    };

    const handleStartNewConversation = () => {
        setShowNewConversation(true);
    };

    const handleSendMessage = async (e) => {
        e.preventDefault();
        if (!newMessage.trim() || !selectedConversation) return;

        try {
            await sendMessage(selectedConversation.id, user.uid, newMessage);
            setNewMessage('');
        } catch (error) {
            console.error('Error sending message:', error);
        }
    };

    const handleTyping = async (e) => {
        setNewMessage(e.target.value);
        if (!selectedConversation) return;

        try {
            await updateTypingStatus(selectedConversation.id, user.uid, e.target.value.length > 0);
        } catch (error) {
            console.error('Error updating typing status:', error);
        }
    };

    const formatMessageTime = (timestamp) => {
        if (!timestamp) return '';
        const date = timestamp.toDate();
        return formatDistanceToNow(date, { addSuffix: true });
    };

    const isUserTyping = (userId) => {
        if (!typingStatus[userId]) return false;
        const now = Date.now();
        const typingTimestamp = typingStatus[userId].toDate().getTime();
        return now - typingTimestamp < 5000; // Consider typing active for 5 seconds
    };

    const handleReaction = async (messageId, reaction) => {
        if (!selectedConversation) return;

        try {
            const message = messages.find(m => m.id === messageId);
            const hasReacted = message.reactions?.[user.uid] === reaction;

            if (hasReacted) {
                await removeReaction(selectedConversation.id, messageId, user.uid);
            } else {
                await addReaction(selectedConversation.id, messageId, user.uid, reaction);
            }
        } catch (error) {
            console.error('Error handling reaction:', error);
        }
    };

    const handleFileSelect = () => {
        // Disabled for now
        alert('File sharing is currently disabled to control costs. Coming soon!');
        // fileInputRef.current?.click();
    };

    const formatLastSeen = (timestamp) => {
        if (!timestamp) return 'Never';
        return formatDistanceToNow(timestamp.toDate(), { addSuffix: true });
    };

    return (
        <>
            <MessagesContainer>
                <ConversationsList>
                    <ConversationListHeader>
                        <h2>Messages</h2>
                        <NewMessageButton onClick={handleStartNewConversation}>
                            <FiEdit />
                        </NewMessageButton>
                    </ConversationListHeader>
                    
                    <SearchContainer>
                        <FiSearch />
                        <input
                            type="text"
                            placeholder="Search conversations..."
                            value={searchQuery}
                            onChange={(e) => setSearchQuery(e.target.value)}
                        />
                    </SearchContainer>

                    {filteredConversations.length > 0 ? (
                        filteredConversations.map(conversation => (
                            <ConversationItem
                                key={conversation.id}
                                active={selectedConversation?.id === conversation.id}
                                onClick={() => setSelectedConversation(conversation)}
                            >
                                <ConversationItemContent>
                                    <UserAvatar 
                                        src={conversation.otherUser?.photoURL || '/default-avatar.png'} 
                                        alt={conversation.otherUser?.displayName}
                                    />
                                    <div className="conversation-details">
                                        <UserName>{conversation.otherUser?.displayName || 'Unknown User'}</UserName>
                                        {conversation.lastMessage && (
                                            <div className="last-message">
                                                {conversation.lastMessage.text}
                                                <MessageTime>
                                                    {formatMessageTime(conversation.lastMessage.timestamp)}
                                                </MessageTime>
                                            </div>
                                        )}
                                    </div>
                                </ConversationItemContent>
                            </ConversationItem>
                        ))
                    ) : (
                        <div style={{ padding: 20, color: 'var(--text-secondary)' }}>
                            {searchQuery ? 'No conversations found' : 'No conversations yet'}
                        </div>
                    )}
                </ConversationsList>

                <ChatWindow>
                    {selectedConversation ? (
                        <>
                            <ConversationHeader>
                                <UserAvatar 
                                    src={selectedConversation.otherUser?.photoURL || '/default-avatar.png'} 
                                    alt={selectedConversation.otherUser?.displayName}
                                />
                                <UserInfo>
                                    <UserName>{selectedConversation.otherUser?.displayName}</UserName>
                                    <LastActive>
                                        {isUserTyping(selectedConversation.otherUser?.uid) 
                                            ? 'typing...' 
                                            : lastSeen[selectedConversation.otherUser?.uid]
                                                ? `Last seen ${formatLastSeen(lastSeen[selectedConversation.otherUser?.uid])}`
                                                : 'Offline'}
                                    </LastActive>
                                </UserInfo>
                            </ConversationHeader>

                            <MessagesList>
                                {messages.map(message => (
                                    <MessageBubble
                                        key={message.id}
                                        isMine={message.senderId === user.uid}
                                    >
                                        {message.text}
                                        <MessageActions>
                                            <ReactionButton
                                                onClick={() => setShowEmojiPicker(message.id)}
                                            >
                                                <FiSmile />
                                            </ReactionButton>
                                            {showEmojiPicker === message.id && (
                                                <EmojiPicker>
                                                    {REACTIONS.map(reaction => (
                                                        <Reaction
                                                            key={reaction}
                                                            onClick={() => {
                                                                handleReaction(message.id, reaction);
                                                                setShowEmojiPicker(null);
                                                            }}
                                                            className={
                                                                message.reactions?.[user.uid] === reaction 
                                                                    ? 'active' 
                                                                    : ''
                                                            }
                                                        >
                                                            {reaction}
                                                        </Reaction>
                                                    ))}
                                                </EmojiPicker>
                                            )}
                                        </MessageActions>
                                        {message.reactions && (
                                            <ReactionsList>
                                                {Object.entries(message.reactions).map(([userId, reaction]) => (
                                                    <Reaction
                                                        key={`${userId}-${reaction}`}
                                                        onClick={() => handleReaction(message.id, reaction)}
                                                        className={userId === user.uid ? 'active' : ''}
                                                    >
                                                        {reaction}
                                                    </Reaction>
                                                ))}
                                            </ReactionsList>
                                        )}
                                        <div className="message-footer">
                                            <MessageTime>
                                                {formatMessageTime(message.timestamp)}
                                            </MessageTime>
                                            {message.senderId === user.uid && (
                                                <ReadReceipt isRead={message.read}>
                                                    <FiCheck />
                                                    <FiCheck />
                                                </ReadReceipt>
                                            )}
                                        </div>
                                    </MessageBubble>
                                ))}
                                <div ref={messagesEndRef} />
                            </MessagesList>

                            {Object.keys(typingStatus).map(userId => (
                                userId !== user.uid && isUserTyping(userId) && (
                                    <TypingIndicator key={userId}>
                                        {selectedConversation.otherUser?.displayName} is typing...
                                    </TypingIndicator>
                                )
                            ))}

                            <MessageInput>
                                <FileInput
                                    type="file"
                                    ref={fileInputRef}
                                    accept="image/*,.pdf,.doc,.docx"
                                    onChange={() => {}}
                                />
                                <button onClick={handleFileSelect} title="Attach file (coming soon)">
                                    <FiPaperclip />
                                </button>
                                <button onClick={handleFileSelect} title="Send image (coming soon)">
                                    <FiImage />
                                </button>
                                <input
                                    type="text"
                                    value={newMessage}
                                    onChange={handleTyping}
                                    placeholder="Type a message..."
                                    onKeyPress={(e) => e.key === 'Enter' && handleSendMessage(e)}
                                />
                                <button onClick={handleSendMessage}>Send</button>
                            </MessageInput>
                        </>
                    ) : (
                        <EmptyState>
                            <h3>Welcome to Messages</h3>
                            <p>Select a conversation or start a new one to begin chatting</p>
                            <button onClick={handleStartNewConversation}>
                                Start New Conversation
                            </button>
                        </EmptyState>
                    )}
                </ChatWindow>
            </MessagesContainer>

            {showNewConversation && (
                <Modal onClick={() => setShowNewConversation(false)}>
                    <ModalContent onClick={e => e.stopPropagation()}>
                        <ModalHeader>
                            <h3>New Conversation</h3>
                            <button onClick={() => setShowNewConversation(false)}>&times;</button>
                        </ModalHeader>

                        <UserList>
                            {following.length > 0 ? (
                                following.map(user => (
                                    <UserItem
                                        key={user.uid}
                                        onClick={() => handleSelectUser(user)}
                                    >
                                        <UserAvatar 
                                            src={user.photoURL || '/default-avatar.png'} 
                                            alt={user.displayName}
                                        />
                                        <div>
                                            <UserName>{user.displayName}</UserName>
                                            {user.username && (
                                                <div style={{ fontSize: '0.8em', color: 'var(--text-secondary)' }}>
                                                    @{user.username}
                                                </div>
                                            )}
                                        </div>
                                    </UserItem>
                                ))
                            ) : (
                                <div style={{ padding: 15, textAlign: 'center' }}>
                                    You are not following anyone yet
                                </div>
                            )}
                        </UserList>
                    </ModalContent>
                </Modal>
            )}
        </>
    );
};

export default Messages; 
