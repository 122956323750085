import React from 'react';

const ReactionNotificationItem = ({ data }) => {
    return (
        <div className="notification-item">
            <div>
                <img src={data.userPhotoURL} alt={data.userName} />
                <div className="notification-content">
                    <span>
                        <strong>{data.userName}</strong> reacted to your post with {data.reactionType}
                    </span>
                    <span className="timestamp">{data.formattedTimestamp}</span>
                </div>
            </div>
        </div>
    );
};

export default ReactionNotificationItem;
