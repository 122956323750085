import React from 'react';

const TrainingLogNotificationItem = ({ data }) => {
    return (
        <div className="notification-item">
            <div>
                <img src={data.userPhotoURL} alt={data.userName} />
                <div className="notification-content">
                    <span>
                        <strong>{data.userName}</strong> logged a training session
                    </span>
                    <span className="timestamp">{data.formattedTimestamp}</span>
                    <p>{data.trainingSummary}</p>
                </div>
            </div>
            <div className="notification-item-footer">
                <button className="like-button">Like</button>
                <button className="comment-button">Comment</button>
            </div>
        </div>
    );
};

export default TrainingLogNotificationItem;
