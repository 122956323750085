import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { doc, getDoc, updateDoc, arrayUnion, arrayRemove } from 'firebase/firestore';
import '../../../styles/NotificationItem.css';
import { firestore, auth } from '../../../services/firebase';
import { useAuthState } from 'react-firebase-hooks/auth';

const defaultAvatarPath = '/assets/default-avatar.png';

const FollowNotificationItem = ({ data }) => {
    const [user] = useAuthState(auth);
    const [followedUserName, setFollowedUserName] = useState('another user');
    const [followedUserPhotoURL, setFollowedUserPhotoURL] = useState('');
    const [profilePicError, setProfilePicError] = useState(false);
    const [isFollowing, setIsFollowing] = useState(false);

    useEffect(() => {
        const fetchFollowedUserInfo = async () => {
            if (!user || !data.details?.followerId) return;

            try {
                // Get current user's following list
                const currentUserDoc = await getDoc(doc(firestore, 'users', user.uid));
                const currentUserData = currentUserDoc.data();
                setIsFollowing(currentUserData?.following?.includes(data.details.followerId) || false);

                // Get follower's info
                const followerDoc = await getDoc(doc(firestore, 'users', data.details.followerId));
                if (followerDoc.exists()) {
                    const followerData = followerDoc.data();
                    setFollowedUserName(followerData.displayName || 'another user');
                    setFollowedUserPhotoURL(followerData.photoURL || defaultAvatarPath);
                }
            } catch (error) {
                console.error('Error fetching user data:', error);
            }
        };

        fetchFollowedUserInfo();
    }, [user, data.details?.followerId]);

    const handleFollow = async () => {
        if (!user || !data.details?.followerId) return;

        try {
            const userRef = doc(firestore, 'users', user.uid);
            const targetUserRef = doc(firestore, 'users', data.details.followerId);

            if (isFollowing) {
                // Unfollow
                await updateDoc(userRef, {
                    following: arrayRemove(data.details.followerId)
                });
                await updateDoc(targetUserRef, {
                    followers: arrayRemove(user.uid)
                });
                setIsFollowing(false);
            } else {
                // Follow
                await updateDoc(userRef, {
                    following: arrayUnion(data.details.followerId)
                });
                await updateDoc(targetUserRef, {
                    followers: arrayUnion(user.uid)
                });
                setIsFollowing(true);
            }
        } catch (error) {
            console.error('Error updating follow state:', error);
        }
    };

    const handleProfilePicError = () => {
        setProfilePicError(true);
    };

    // Don't show follow button for own notifications
    if (data.details?.followerId === user?.uid) {
        return null;
    }

    return (
        <div className="notification-item">
            <div>
                <Link to={`/profile/${data.details?.followerId}`}>
                    <img
                        src={profilePicError ? defaultAvatarPath : (data.details?.userPhotoURL || defaultAvatarPath)}
                        alt="Profile"
                        className="profile-pic"
                        onError={handleProfilePicError}
                    />
                </Link>
                <div className="notification-content">
                    <span>
                        <Link to={`/profile/${data.details?.followerId}`}>
                            {data.details?.followerName || 'A user'}
                        </Link>
                        {' '}followed you
                    </span>
                    <span className="timestamp">
                        {data.formattedTimestamp}
                    </span>
                </div>
            </div>
            <button 
                className={`follow-button ${isFollowing ? 'following' : ''}`}
                onClick={handleFollow}
            >
                {isFollowing ? 'Following' : 'Follow'}
            </button>
        </div>
    );
};

export default FollowNotificationItem;
