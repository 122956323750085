import React from 'react';

const MediaPostNotificationItem = ({ data }) => {
    return (
        <div className="notification-item media-post-notification-item">
            <div>
                <img src={data.userPhotoURL} alt={data.userName} />
                <div className="notification-content">
                    <span>
                        <strong>{data.userName}</strong> shared a {data.mediaType}
                    </span>
                    <span className="timestamp">{data.formattedTimestamp}</span>
                </div>
            </div>
            {data.mediaType === 'photo' && <img src={data.mediaUrl} alt="Shared content" />}
            {data.mediaType === 'video' && <video src={data.mediaUrl} controls />}
            {data.mediaType === 'link' && (
                <a href={data.mediaUrl} target="_blank" rel="noopener noreferrer">
                    {data.mediaUrl}
                </a>
            )}
            <div className="notification-item-footer">
                <button className="like-button">Like</button>
                <button className="comment-button">Comment</button>
            </div>
        </div>
    );
};

export default MediaPostNotificationItem;
