import React from 'react';
import { Link } from 'react-router-dom';

const ReplyNotificationItem = ({ data }) => {
    return (
        <div className="notification-item">
            <div>
                <img src={data.userPhotoURL} alt={data.userName} />
                <div className="notification-content">
                    <span>
                        <Link to={`/profile/${data.replierId}`}><strong>{data.replierName}</strong></Link> replied to your comment: {data.replyText}
                    </span>
                    <span className="timestamp">{data.formattedTimestamp}</span>
                </div>
            </div>
        </div>
    );
};

export default ReplyNotificationItem;
