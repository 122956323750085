import React, { useState } from 'react';
import { Link } from 'react-router-dom';

const defaultAvatarPath = '/assets/default-avatar.png';

const GymFavoriteNotificationItem = ({ data }) => {
    const [profilePicError, setProfilePicError] = useState(false);

    if (!data?.details) {
        return <div>Error: Gym favorite data is not available.</div>;
    }

    const handleProfilePicError = () => {
        setProfilePicError(true);
    };

    return (
        <div className="notification-item">
            <div>
                <img
                    src={profilePicError ? defaultAvatarPath : (data.details.userPhotoURL || defaultAvatarPath)}
                    alt={`${data.details.userName}'s profile`}
                    onError={handleProfilePicError}
                />
                <div className="notification-content">
                    <Link to={`/profile/${data.details.userId}`}>
                        {data.details.userName || 'A user'}
                    </Link>{' '}
                    favorited{' '}
                    <Link to={`/gym/${data.details.gymId}`}>
                        {data.details.gymName}
                    </Link>
                    <span className="timestamp">{data.formattedTimestamp}</span>
                </div>
            </div>
        </div>
    );
};

export default GymFavoriteNotificationItem;
