import { firestore } from './firebase.js';
import { doc, getDoc, collection, getDocs, query, where } from 'firebase/firestore';

export const getUserData = async (userId) => {
    try {
        const userDocRef = doc(firestore, 'users', userId);
        const userDoc = await getDoc(userDocRef);
        if (userDoc.exists()) {
            return { id: userDoc.id, ...userDoc.data() };
        }
        return null;
    } catch (error) {
        console.error('Error fetching user data:', error);
        return null;
    }
};

export const isUserCreator = async (userId) => {
    try {
        const userDoc = await getDoc(doc(firestore, 'users', userId));
        if (userDoc.exists()) {
            const userData = userDoc.data();
            return userData.role?.creator || false;
        }
        return false;
    } catch (error) {
        console.error('Error checking creator status:', error);
        return false;
    }
};

export const isUserSubscribed = async (userId, creatorId) => {
    try {
        const subscriptionDoc = await getDoc(doc(firestore, 'users', creatorId, 'subscribers', userId));
        return subscriptionDoc.exists();
    } catch (error) {
        console.error('Error checking subscription status:', error);
        return false;
    }
};

export const fetchUserEvents = async (attending = []) => {
    const now = new Date();
    const oneWeekAgo = new Date(now.getTime() - 7 * 24 * 60 * 60 * 1000);

    const futureEvents = [];
    const pastEvents = [];

    for (const event of attending) {
        const eventDate = new Date(event.start.seconds * 1000);
        if (eventDate > now) {
            futureEvents.push(event);
        } else if (eventDate > oneWeekAgo) {
            pastEvents.push(event);
        }
    }

    // Add location names to events
    const addLocationNames = async (events) => {
        return Promise.all(events.map(async (event) => {
            if (!event.locationName && event.locationId) {
                const locationDoc = await getDoc(doc(firestore, 'locations', event.locationId));
                if (locationDoc.exists()) {
                    event.locationName = locationDoc.data().name;
                }
            }
            return event;
        }));
    };

    const futureEventsWithLocation = await addLocationNames(futureEvents);
    const pastEventsWithLocation = await addLocationNames(pastEvents);

    return {
        futureEventsWithLocation,
        pastEventsWithLocation
    };
};

export const fetchUserFollowing = async (followingIds = []) => {
    const following = await Promise.all(
        followingIds.map(async (followId) => {
            const followDoc = await getDoc(doc(firestore, 'users', followId));
            return followDoc.exists() ? { uid: followDoc.id, ...followDoc.data() } : null;
        })
    );
    return following.filter(follow => follow !== null);
};

export const fetchUserFollowers = async (followerIds = []) => {
    const followers = await Promise.all(
        followerIds.map(async (followerId) => {
            const followerDoc = await getDoc(doc(firestore, 'users', followerId));
            return followerDoc.exists() ? { uid: followerDoc.id, ...followerDoc.data() } : null;
        })
    );
    return followers.filter(follower => follower !== null);
};

export const fetchUserFavoriteGyms = async (gymIds = []) => {
    const favoriteGyms = await Promise.all(
        gymIds.map(async (gymId) => {
            const gymDoc = await getDoc(doc(firestore, 'locations', gymId));
            return gymDoc.exists() ? { id: gymId, ...gymDoc.data() } : null;
        })
    );
    return favoriteGyms.filter(gym => gym !== null);
};

export const fetchUserComments = async (userId) => {
    try {
        const commentsRef = collection(firestore, `users/${userId}/comments`);
        const commentsSnapshot = await getDocs(commentsRef);
        return commentsSnapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data()
        }));
    } catch (error) {
        console.error('Error fetching user comments:', error);
        return [];
    }
}; 