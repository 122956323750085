import React from 'react';
import { Link } from 'react-router-dom';

const GymPinNotificationItem = ({ data }) => {
    return (
        <div className="notification-item">
            <div>
                <img src={data.userPhotoURL} alt={data.userName} />
                <div className="notification-content">
                    <span>
                        <strong>{data.userName}</strong> pinned <Link to={`/gym/${data.gymId}`}>{data.gymName}</Link>
                    </span>
                    <span className="timestamp">{data.formattedTimestamp}</span>
                </div>
            </div>
        </div>
    );
};

export default GymPinNotificationItem;
