import { 
    collection,
    query,
    where,
    orderBy,
    addDoc,
    updateDoc,
    doc,
    getDoc,
    getDocs,
    onSnapshot,
    serverTimestamp,
    deleteField
} from 'firebase/firestore';
import { firestore } from './firebase';

// Create a new conversation or get existing one
export const getOrCreateConversation = async (currentUserId, otherUserId) => {
    const conversationsRef = collection(firestore, 'conversations');
    
    // Check if conversation exists
    const q = query(
        conversationsRef,
        where('participants', 'array-contains', currentUserId)
    );
    
    const querySnapshot = await getDocs(q);
    const existingConversation = querySnapshot.docs.find(doc => {
        const data = doc.data();
        return data.participants.includes(otherUserId);
    });

    if (existingConversation) {
        return { id: existingConversation.id, ...existingConversation.data() };
    }

    // Create new conversation
    const newConversation = {
        participants: [currentUserId, otherUserId],
        lastMessage: null,
        createdAt: serverTimestamp(),
        updatedAt: serverTimestamp()
    };

    const docRef = await addDoc(conversationsRef, newConversation);
    return { id: docRef.id, ...newConversation };
};

// Send a message
export const sendMessage = async (conversationId, senderId, text) => {
    const messagesRef = collection(firestore, `conversations/${conversationId}/messages`);
    
    const message = {
        senderId,
        text,
        timestamp: serverTimestamp(),
        read: false
    };

    const messageDoc = await addDoc(messagesRef, message);
    
    // Update conversation's last message
    const conversationRef = doc(firestore, 'conversations', conversationId);
    await updateDoc(conversationRef, {
        lastMessage: {
            text,
            timestamp: serverTimestamp()
        },
        updatedAt: serverTimestamp()
    });

    return { id: messageDoc.id, ...message };
};

// Subscribe to messages in a conversation
export const subscribeToMessages = (conversationId, callback) => {
    const messagesRef = collection(firestore, `conversations/${conversationId}/messages`);
    const q = query(messagesRef, orderBy('timestamp', 'asc'));
    
    return onSnapshot(q, (snapshot) => {
        const messages = snapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data()
        }));
        callback(messages);
    });
};

// Update typing status
export const updateTypingStatus = async (conversationId, userId, isTyping) => {
    const conversationRef = doc(firestore, 'conversations', conversationId);
    await updateDoc(conversationRef, {
        [`typing.${userId}`]: isTyping ? serverTimestamp() : null
    });
};

// Subscribe to typing status
export const subscribeToTypingStatus = (conversationId, callback) => {
    const conversationRef = doc(firestore, 'conversations', conversationId);
    
    return onSnapshot(conversationRef, (doc) => {
        const data = doc.data();
        const typing = data?.typing || {};
        callback(typing);
    });
};

// Mark message as read
export const markMessageAsRead = async (conversationId, messageId) => {
    const messageRef = doc(firestore, `conversations/${conversationId}/messages/${messageId}`);
    await updateDoc(messageRef, {
        read: true,
        readAt: serverTimestamp()
    });
};

// Get user's conversations
export const getUserConversations = async (userId) => {
    const conversationsRef = collection(firestore, 'conversations');
    const q = query(
        conversationsRef,
        where('participants', 'array-contains', userId),
        orderBy('updatedAt', 'desc')
    );
    
    const querySnapshot = await getDocs(q);
    return querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
    }));
};

// Subscribe to user's conversations
export const subscribeToUserConversations = (userId, callback) => {
    const conversationsRef = collection(firestore, 'conversations');
    const q = query(
        conversationsRef,
        where('participants', 'array-contains', userId),
        orderBy('updatedAt', 'desc')
    );
    
    return onSnapshot(q, (snapshot) => {
        const conversations = snapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data()
        }));
        callback(conversations);
    });
};

// Fetch user info for a participant
export const fetchUserInfo = async (userId) => {
    try {
        const userDoc = await getDoc(doc(firestore, 'users', userId));
        if (userDoc.exists()) {
            return {
                uid: userId,
                ...userDoc.data()
            };
        }
        return null;
    } catch (error) {
        console.error('Error fetching user info:', error);
        return null;
    }
};

// Enhanced version of getOrCreateConversation that includes user info
export const getOrCreateConversationWithUserInfo = async (currentUserId, otherUserId) => {
    const conversation = await getOrCreateConversation(currentUserId, otherUserId);
    const otherUserInfo = await fetchUserInfo(otherUserId);
    
    return {
        ...conversation,
        otherUser: otherUserInfo
    };
};

// Enhanced version of subscribeToUserConversations that includes user info
export const subscribeToUserConversationsWithInfo = (userId, callback) => {
    return subscribeToUserConversations(userId, async (conversations) => {
        const conversationsWithUserInfo = await Promise.all(
            conversations.map(async (conversation) => {
                const otherUserId = conversation.participants.find(id => id !== userId);
                const otherUserInfo = await fetchUserInfo(otherUserId);
                
                return {
                    ...conversation,
                    otherUser: otherUserInfo
                };
            })
        );
        
        callback(conversationsWithUserInfo);
    });
};

// Add reaction to message
export const addReaction = async (conversationId, messageId, userId, reaction) => {
    const messageRef = doc(firestore, `conversations/${conversationId}/messages/${messageId}`);
    await updateDoc(messageRef, {
        [`reactions.${userId}`]: reaction
    });
};

// Remove reaction from message
export const removeReaction = async (conversationId, messageId, userId) => {
    const messageRef = doc(firestore, `conversations/${conversationId}/messages/${messageId}`);
    await updateDoc(messageRef, {
        [`reactions.${userId}`]: deleteField()
    });
};

// Update user's last seen timestamp
export const updateLastSeen = async (conversationId, userId) => {
    const conversationRef = doc(firestore, 'conversations', conversationId);
    await updateDoc(conversationRef, {
        [`lastSeen.${userId}`]: serverTimestamp()
    });
};

// Get user's last seen timestamp
export const getLastSeen = async (conversationId, userId) => {
    const conversationRef = doc(firestore, 'conversations', conversationId);
    const conversationDoc = await getDoc(conversationRef);
    if (conversationDoc.exists()) {
        const data = conversationDoc.data();
        return data.lastSeen?.[userId] || null;
    }
    return null;
}; 
